import React from "react"

import PhoneIcon from "../images/phone.svg"
import EmailIcon from "../images/email.svg"
import StoreLocatorIcon from "../images/icon-store-locator.svg"
import Facebook from "../images/facebook.png"
import Youtube from "../images/youtube.png"
import Instagram from "../images/instagram.png"
import Linkedin from "../images/linkedin.png"
import IconRight from "../images/icon_arrow_right.svg"
import IconDown from "../images/icon_arrow_down.svg"

const Footer = ({ siteTitle }) => (
  <div class="container-full-width non-printable border-top border-bottom">
    <div class="pt-3 footer text-center">
      <div class="container">
        <footer>
          <div class="row pb-3">
            <div class="col-xl-3 col-sm-6">
              <h4 class="amatic-bold pt-3 mb-0 footer-text-title mb-3">
                <a href="https://www.penny.ro/companie" target="_blank">Companie</a>
              </h4>
              <ul class="list-unstyled">
                <li>
                  <a class="footer-link" href="https://www.penny.ro/AboutUs">
                    Despre Penny
                  </a>
                </li>
                <li>
                  <a class="footer-link" href="https://www.penny.ro/principiile-penny">
                    Principiile Companiei
                  </a>
                </li>
                <li>
                  <a class="footer-link" href="https://www.penny.ro/expansiune">
                    Dezvoltare & Expansiune
                  </a>
                </li>
                <li>
                  <a class="footer-link" href="https://www.penny.ro/presa">
                    Comunicate de presă & Publicații
                  </a>
                </li>
              </ul>
            </div>

            <div class="col-xl-3 col-sm-6">
              <h4 class="amatic-bold pt-3 mb-0 footer-text-title mb-3">
                <a href="https://sustenabilitate.penny.ro/" target="_blank">Sustenabilitate</a>
              </h4>
              <ul class="list-unstyled">
                <li>
                  <a href="https://sustenabilitate.penny.ro/sortiment-sustenabil/" class="footer-link">
                    Sortiment sustenabil
                  </a>
                </li>
                <li>
                  <a href="https://sustenabilitate.penny.ro/energie-clima-resurse/" class="footer-link">
                    Energie, climă și resurse
                  </a>
                </li>
                <li>
                  <a href="https://sustenabilitate.penny.ro/angajati-societate/" class="footer-link">
                    Angajați și societate
                  </a>
                </li>
              </ul>
            </div>
            <div class="col-xl-3 col-sm-6">
              <h4 class="amatic-bold pt-3 mb-0 footer-text-title mb-2">
                Utile și
              </h4>
              <h6 class="footer-text akko-bold accentual-heading-h3-font akko-medium mb-3">
                Informații de contact
              </h6>
              <ul class="list-unstyled">
                <li>
                  <div class="d-flex">
                    <div class="mr-1">
                      <img style={{width:"25px"}} class="mr-3"
                        src="https://assets-eu-01.kc-usercontent.com:443/b50b8cce-450a-0138-7b43-de6bc2f3ad32/1fcc0022-049d-42f2-babc-cc8640ffc0c4/Contact_R.svg" alt="" />
                    </div>
                    <p class="text-left">
                      <span class="header-icons well-dark-grey-color">
                        0800 110 111
                      </span>
                      <br />
                      <span class="header-icons well-dark-grey-color">
                        Luni - Sâmbătă 08:00 - 21:00
                      </span>
                    </p>
                  </div>
                </li>
                <li>
                  <div class="d-flex">
                    <img style={{width:"25px"}} class="mr-3" src="https://assets-eu-01.kc-usercontent.com:443/b50b8cce-450a-0138-7b43-de6bc2f3ad32/b286899b-42ff-40a4-b25b-bf941381c616/Email_R.svg" alt="" />
                    <a style={{lineHeight:"33px"}} href="https://www.penny.ro/contact" class="footer-link">
                      Contact
                    </a>
                  </div>
                </li>
                <li>
                  <div class="d-flex mt-3">
                    <img style={{width:"25px"}} class="mr-3" src="https://fotbal.penny.ro/resources/footer/icon-store-locator.svg"
                      alt="faq_icon" width="35px" height="35px" />

                    <a href="https://www.penny.ro/my-stores#/" class="footer-link text-nowrap">
                      Magazinul meu
                    </a>
                  </div>
                </li>
              </ul>
            </div>
            <div class="col-xl-3 col-sm-6">
              <h4 class="amatic-bold pt-3 mb-0 footer-text-title mb-2">
                Urmărește-ne și pe
              </h4>
              <h6 class="footer-text akko-bold accentual-heading-h3-font akko-medium mb-3">
                SOCIAL MEDIA &amp; APP
              </h6>
              <ul class="list-inline list-unstyled">
                <li class="list-inline-item">
                  <a href="https://www.facebook.com/penny.ro">
                    <img class="facebook" src="https://assets-eu-01.kc-usercontent.com/b50b8cce-450a-0138-7b43-de6bc2f3ad32/a2dcfa43-2684-440a-a815-d117c875f5e3/Facebook-Hover.png?w=35&fm=webp&lossless=0&q=80&dpr=2"
                      alt="facebook" />
                  </a>
                </li>

                <li class="list-inline-item">
                  <a href="https://www.youtube.com/pennymarketromania">
                    <img class="youtube" src="https://assets-eu-01.kc-usercontent.com:443/b50b8cce-450a-0138-7b43-de6bc2f3ad32/f2626236-a9a2-40e2-94bf-4e786e77d483/YouTube.png?w=35&fm=webp&lossless=0&q=80"
                      alt="youtube" />
                  </a>
                </li>
                <li class="list-inline-item">
                  <a href="https://www.instagram.com/pennymarket.ro/">
                    <img class="instagram" src="https://assets-eu-01.kc-usercontent.com:443/b50b8cce-450a-0138-7b43-de6bc2f3ad32/d383a6ac-275f-450d-99ff-d62489071bc9/instagram.png?w=35&fm=webp&lossless=0&q=80"
                      alt="instagram" />
                  </a>
                </li>
                <li class="list-inline-item">
                  <a href="https://www.linkedin.com/company/129255/">
                    <img class="linkedin" src="https://assets-eu-01.kc-usercontent.com:443/b50b8cce-450a-0138-7b43-de6bc2f3ad32/612bc7fa-3b3a-434f-96b5-249bf5f482cc/linked%20in_35%20width.png?w=41&fm=webp&lossless=0&q=80"
                      alt="linkedin" />
                  </a>
                </li>
                {/* </br> */}
                  <li class="list-inline-item my-3">
                    <a href="https://play.google.com/store/apps/details?id=ro.penny.app">
                      <img width="100px" alt="alternate text"
                        src="https://preview-assets-eu-01.kc-usercontent.com/b50b8cce-450a-0138-7b43-de6bc2f3ad32/a482523f-c1d7-4be7-a27e-7b03be1b6d65/Google-Play.png?w=100&fm=webp&lossless=0&q=80&dpr=2"
                        class="app-store" />
                    </a>
                  </li>
                  <li class="list-inline-item my-3">
                    <a href="https://itunes.apple.com/ro/app/penny-market/id1091585551?mt=8">
                      <img width="100px" alt="alternate text"
                        src="https://assets-eu-01.kc-usercontent.com:443/b50b8cce-450a-0138-7b43-de6bc2f3ad32/9b3c081c-4d8e-4971-bf73-5b18a475e78f/app-store-badge.svg?w=100&fm=webp&dpr=1&q=80"
                        class="app-store" />
                    </a>
                  </li>
                  <h6 class="footer-text accentual-heading-h3-font akko-medium mb-3">
                    Livrare la domiciliu prin:
                  </h6>
                  <li class="list-inline-item">
                    <a href="https://glovoapp.com/ro/ro/bucharest/penny-buc/">
                      <img width="100px" alt="alternate text"
                        src="https://preview-assets-eu-01.kc-usercontent.com/b50b8cce-450a-0138-7b43-de6bc2f3ad32/7b50fe65-d4e3-437d-ac1f-792de05375be/glovo-logo.png?w=100&fm=webp&lossless=0&q=80&dpr=2"
                        class="app-store" />
                    </a>
                  </li>
                  <li class="list-inline-item ">
                    <a href="https://www.bringo.ro/ro/">
                      <img width="100px" alt="alternate text"
                        src="https://preview-assets-eu-01.kc-usercontent.com/b50b8cce-450a-0138-7b43-de6bc2f3ad32/c1b0417e-fbab-477a-a1f9-4a4efba00dc1/logo-bringo.png?w=100&fm=webp&lossless=0&q=80&dpr=2"
                        class="app-store" />
                    </a>
                  </li>
                  <li class="list-inline-item my-4 px-4">
                    <a href="https://tazz.ro/">
                      <img width="100px" alt="alternate text"
                        src="https://preview-assets-eu-01.kc-usercontent.com/b50b8cce-450a-0138-7b43-de6bc2f3ad32/975f401d-8491-4a04-90cc-1c5f5b19dfeb/tazz-logo.png?w=100&fm=webp&lossless=0&q=80&dpr=2"
                        class="app-store" />
                    </a>
                  </li>
              </ul>
            </div>
          </div>
          <div class="row pb-3">
            <div class="col text-center mb-5">

              <img height="77px" alt="alternate text"
                src="https://preview-assets-eu-01.kc-usercontent.com/b50b8cce-450a-0138-7b43-de6bc2f3ad32/f3198f22-7e15-4b0d-9dd1-28182a0e6546/Top_Employer_Romania_2025.png?w=200&fm=webp&lossless=0&q=80&dpr=2"
                class="app-store mx-5" />

              <a href="https://anpc.ro/">
                <img height="51px" alt="alternate text"
                  src="https://preview-assets-eu-01.kc-usercontent.com/b50b8cce-450a-0138-7b43-de6bc2f3ad32/32f50dd4-4174-47aa-973f-b49499c5e371/anpc.png?w=200&fm=webp&lossless=0&q=80&dpr=2"
                  class="app-store mx-5" />
              </a>
              <a href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=RO">
                <img height="51px" alt="alternate text"
                  src="https://preview-assets-eu-01.kc-usercontent.com/b50b8cce-450a-0138-7b43-de6bc2f3ad32/6773f8ba-14f0-4918-a1b2-0f8b97077d30/sol.png?w=200&fm=webp&lossless=0&q=80&dpr=2"
                  class="app-store mx-5" />
              </a>

            </div>
          </div>
        </footer>
      </div>
    </div>
    <div class="container">
      <ul class="d-flex akko-bold text-center px-0 px-md-8 mt-4 list-unstyled list-inline">
        <div class="d-flex flex-md-row flex-column mx-auto">
          <li class="font-weight-bold mx-2">
            <a href="https://www.penny.ro//page/termeni-conditii" class="footer-link footer-terms">
              Termeni și condiții
            </a>
          </li>
          <li class="font-weight-bold mx-2">
            <a href="https://www.penny.ro//page/cookies" class="footer-link footer-terms">
              Politica de cookies
            </a>
          </li>
          <li class="font-weight-bold mx-2">
            <a href="https://www.penny.ro//page/confidentialitate" class="footer-link footer-terms">
              Politica de confidențialitate
            </a>
          </li>
          <li class="font-weight-bold mx-2">
            <a href="https://www.penny.ro//page/imprint" class="footer-link footer-terms">
              Sediul social
            </a>
          </li>
          <li class="font-weight-bold mx-2">
            <a href="https://www.penny.ro//page/compliance" class="footer-link footer-terms">
              Compliance
            </a>
          </li>
        </div>
      </ul>
    </div>
  </div>
)

export default Footer
